/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useSocket } from "../lib/SocketIo";
import Modal from 'react-bootstrap/Modal';
import { useHeGame } from "../lib/GameProvider";
import { validatePhone, cleanMsisdn } from "../utils/formatters";

const channel = process.env.REACT_APP_API_CHANNEL || "gamesfrenzy"

export default function GiftModal() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { emit } = useSocket()

  // wrap the functionality into a hook so we can use it across components
  const {
    msisdnHash,
    ipAddress,
    sessionId,
    subscribed,
    loading,
    error,
    queryParams,
    giftModal,
    setGiftModal,
    setError,
  } = useHeGame({ navigate, searchParams })

  // Component state 
  const [msisdn, setMsisdn] = useState<string>()

  function gotoGames() {
    setGiftModal?.(false)
    setError?.('')
    if (subscribed) {
      navigate(`/games?${searchParams.toString()}`)
    }
  }

  const handleMsisdnChange = (e: any) => {
    setMsisdn(e.target.value);
  };

  const handleGiftSubmit = (event: any) => {
    event.preventDefault();

    if (msisdn) {
      // Perform validation
      let isValid = validatePhone(msisdn);
      if (!isValid) {
        setError?.('Please enter valid phone')
        return
      }

      // Claim gift
      emit('claim-gift', JSON.stringify({
        hashed_msisdn: msisdnHash,
        msisdn: cleanMsisdn(msisdn),
        channel: channel,
        ip_address: ipAddress,
        session_id: sessionId,
        params: queryParams,
      }))
    }

    gotoGames()
  };

  return <Modal show={giftModal} onHide={() => { gotoGames() }} centered backdrop>
    {/* <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: 'center' }}>
              <div>Enter mobile for Gift</div>
            </Modal.Title>
          </Modal.Header> */}
    <Modal.Body>
      <div className="text-center">
        <div style={{ marginBottom: '10px' }}>
          Enter Safaricom mobile number to get data bundle
        </div>
        <form id="myForm" className="w-full" onSubmit={handleGiftSubmit}>
          {/* Your form fields */}
          <input type="text" placeholder="Enter Safaricom Mobile Number" className="w-full" onChange={handleMsisdnChange} defaultValue={msisdn} />
          {/* ...other fields */}

          {error && <p className="error-message">{error}</p>}

          <button className="w-full" type="submit" style={{ marginTop: 20 }} disabled={loading}>
            {!loading ? "COMPLETE" : "Please wait.."}
          </button>
        </form>
      </div>
    </Modal.Body>
  </Modal>
}
